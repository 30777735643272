import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Line } from 'react-chartjs-2';

const lineChartData = {
  labels: ['Mon', 'Tue', 'Wen', 'Thus', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      data: [10, 59, 80, 81, 56, 55, 40],
      backgroundColor: 'rgba(68, 102, 242, 0.3)',
      borderColor: '#4466f2',
      borderWidth: 2,
    },
  ],
  plugins: {
    datalabels: {
      display: false,
      color: 'white',
    },
  },
};

const lineChart2Data = {
  labels: ['Mon', 'Tue', 'Wen', 'Thus', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      data: [28, 48, 40, 19, 86, 27, 90],
      backgroundColor: 'rgba(30, 166, 236, 0.3)',
      borderColor: "#1ea6ec",
      borderWidth: 2,
    },
  ],
  plugins: {
    datalabels: {
      display: false,
      color: 'white',
    },
  },
};

const lineChartOptions = {
  maintainAspectRatio: true,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

const datasetKeyProvider = () =>{
  return Math.random();
}

const Dashboard = props => {
  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 p-10">
            <div className="card">
              <div className="card-header">
                <h5>تغيرات سعر الدولار $</h5>
              </div>
              <div className="card-body chart-block">
                <Line
                  data={lineChartData}
                  options={lineChartOptions}
                  datasetKeyProvider={datasetKeyProvider}
                  width={778}
                  height={400}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 p-10">
            <div className="card">
              <div className="card-header">
                <h5>تغيرات سعر اليورو €</h5>
              </div>
              <div className="card-body chart-block">
                <Line
                  data={lineChart2Data}
                  options={lineChartOptions}
                  datasetKeyProvider={datasetKeyProvider}
                  width={778}
                  height={400}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 p-10">
            <div className="card">
              <div className="card-header">
                <h5>تغيرات سعر الذهب</h5>
              </div>
              <div className="card-body chart-block">
                <Line
                  data={lineChart2Data}
                  options={lineChartOptions}
                  datasetKeyProvider={datasetKeyProvider}
                  width={778}
                  height={400}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
