import React from 'react';
import {
  Box,
  Title,
  Top,
  Symbol,
  Value,
  Bottom,
  PreValue,
  ChangePercent,
  ChangeArrow,
} from './styles';

const CoinBlock = (props) => {
  return (
    <Box type={props.type}>
      <Top>
        <Title>{props.title}</Title>
        {props.symbol && <Symbol>{props.symbol}</Symbol>}
      </Top>
      <Value>{parseFloat(props.value)}</Value>
      <Bottom>
        <PreValue>{parseFloat(props.preValue)}</PreValue>
        <div>
          <ChangePercent>{parseFloat(props.percent)}%</ChangePercent>
          <ChangeArrow type={props.status} />
        </div>
      </Bottom>
    </Box>
  );
};

export default CoinBlock;
