import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Preloader from './preloader/index';
import { isSSR } from '@utils/helper';

const PreloadComponent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const time = setTimeout(() => {
      setShow(true);
    }, 250);
    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <div className="rtl">
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <div className="page-body"><Preloader show={show} /></div>
        </div>
      </div>
    </div>
  );
};
const SecureRoute = ({ component: Component, location, ...rest }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function loadUser() {
      const { isAuth } = await import('@utils/feathers-connect');
      const user = await isAuth();
      if (!user) {
        navigate('/login');
        return;
      }
      setUser(user);
    }

    !isSSR && loadUser();
  }, []);

  if (isSSR) {
    return <PreloadComponent />;
  }

  if (!localStorage || !user) {
    return <PreloadComponent />;
  }

  return <Component location={location} {...rest} />;
};

export default SecureRoute;
