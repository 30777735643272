export const ErrorMessages = {
  required: 'هذا الحقل مطلوب!',
  minLength: (num) => `يجب أن يحتوي النص على الأقل ${num} حروف`,
  unique: 'هذه القيمة مستخدمة سابقاً, يرجى ادخال قيمة جديدة',
};

export const SERVICES = {
  currency: 'currencies',
  history: 'history',
  notificationTemplates: 'notification-templates',
};

export const RECORDS_PER_PAGE = 20;

export const Tabs = {
  MAIN: 'MAIN',
  DOLLAR: 'DOLLAR',
  CURRENCIES: 'CURRENCIES',
  GOVERNORATE_CURRENCIES: 'GOVERNORATE_CURRENCIES',
  ARABIC_CURRENCIES: 'ARABIC_CURRENCIES',
  FOREIGN_CURRENCIES: 'FOREIGN_CURRENCIES',
  GOLD: 'GOLD',
  MATERIALS: 'MATERIALS',
  NEWS: 'NEWS',
};

export const TabsNames = {
  MAIN: 'الرئيسية',
  DOLLAR: 'الدولار',
  CURRENCIES: 'العملات',
  GOVERNORATE_CURRENCIES: 'المحافظات',
  ARABIC_CURRENCIES: 'العملات العربية',
  FOREIGN_CURRENCIES: 'العملات الأجنبية',
  GOLD: 'الذهب',
  MATERIALS: 'البورصات',
  NEWS: 'الأخبار',
};

export const SiteLocations = {
  TRANSFER: 1,
  GOVERNORATE_CURRENCIES: 2,
  ARABIC_CURRENCIES: 3,
  FOREIGN_CURRENCIES: 4,
  GOLD: 5,
  MATERIALS: 6,
};

export const AppLocations = {
  DOLLAR: 7,
  CURRENCIES: 8,
  GOLD: 9,
  MATERIALS: 10,
};

export const AppScreensList = [
  { id: 1, value: 'MAIN', title: 'الشاشة الرئيسية' },
  { id: 2, value: 'CURRENCIES', title: 'شاشة العملات' },
  { id: 3, value: 'GOVERNORATE_CURRENCIES', title: 'شاشة المحافظات' },
  { id: 4, value: 'ARABIC_CURRENCIES', title: 'شاشة العملات العربية' },
  { id: 5, value: 'FOREIGN_CURRENCIES', title: 'شاشة العملات الأجنبية' },
  { id: 6, value: 'GOLD', title: 'شاشة الذهب' },
  { id: 7, value: 'MATERIALS', title: 'شاشة البورصات' },
  { id: 8, value: 'NEWS', title: 'شاشة الأخبار' },
  { id: 9, value: 'SYRIAN_STOCKS', title: 'الأسهم السورية' },
];
