import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import FilePreview from '@components/file-preview/index';
import Layout from '@layouts/default';
import { Error, Input, Desc } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser } from '@utils/helper';
import { ErrorMessages } from '@utils/consts';
import { toBase64 } from '@utils/formHelper';

let UploadsService = () => {};
let NewsService = () => {};

async function removeImage(imageId, field, setValue, setImageId) {
  await UploadsService.remove(imageId);
  setValue(field, null);
  setImageId(null);
}

const NewsNew = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [imageId, setImageId] = useState();
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      if (id) {
        await NewsService.patch(id, data);
      } else {
        await NewsService.create(data);
      }
      setLoading(false);
      navigate('/dashboard/news/');
    }
  };

  const handleImageChange = async ({ file }, status) => {
    if (status == 'done') {
      const base64File = await toBase64(file);
      const res = await UploadsService.create({ uri: base64File });
      setValue('image', res.id);
    } else if (status == 'removed') {
      const imageId = getValues('image');
      await UploadsService.remove(imageId);
    }
  };

  useEffect(() => {
    register('image');

    async function prepareServices() {
      setLoading(true);
      const { uploadsService, newsService } = await import(
        '@utils/feathers-connect'
      );
      NewsService = newsService;
      UploadsService = uploadsService;

      if (id > 0) {
        const newsData = await newsService.get(id);
        if (!newsData) {
          return;
        }
        setValue('title', newsData.title);
        setValue('content', newsData.content);
        setValue('status', newsData.status ? '1' : '0');
        setValue('image', newsData.image);
        setImageId(newsData.image);
      }
      setLoading(false);
    }
    isBrowser && prepareServices();
  }, [register]);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>{id > 0 ? 'تعديل الخبر' : 'إضافة خبر جديد'}</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      العنوان
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        id="currency-name"
                        type="text"
                        name="title"
                        placeholder="العنوان"
                        autoComplete="off"
                        hasError={hasError(errors, 'title')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      صورة الخبر
                    </label>
                    <div className="col-sm-9 dz-message needsclick">
                      <FilePreview
                        id={id}
                        imageId={imageId}
                        onRemove={(imageId) => removeImage(imageId, 'image', setValue, setImageId)}
                      />
                      <Dropzone
                        onChangeStatus={handleImageChange}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="اختر صورة"
                        styles={{
                          dropzone: { width: 350, height: 120 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="image"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="news-content"
                    >
                      النص
                    </label>
                    <div className="col-sm-9">
                      <Desc
                        className="form-control"
                        id="news-content"
                        name="content"
                        placeholder="نص الخبر"
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="content"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      ظهور الخبر
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-visable"
                            type="radio"
                            name="status"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-visable">إظهار</label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-disable"
                            type="radio"
                            name="status"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-disable">إخفاء</label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="status"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    {id ? 'تحديث' : 'حفظ'}
                  </button>
                  <Link className="btn btn-light mr-1" to={'/dashboard/news/'}>
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default NewsNew;
