import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import FilePreview from '@components/file-preview/index';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Error, Input, Select } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser, onlyNumberKey } from '@utils/helper';
import { ErrorMessages, SERVICES } from '@utils/consts';
import { toBase64, uniqueValueCheck } from '@utils/formHelper';

let CurrenciesService = () => {};
let UploadsService = () => {};
let PublishService = () => {};

async function removeImage(imageId, field, setValue, setImageId) {
  await UploadsService.remove(imageId);
  setValue(field, null);
  setImageId(null);
}

const CurrenciesNew = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [appImageId, setAppImageId] = useState();
  const [webImageId, setWebImageId] = useState();
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    mode: 'onBlur',
  });
  const [siteLocationOptions, setSiteLocationOptions] = useState([]);

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      data.type = 'coin';
      data.isFeatured = data.isFeatured === '1';
      if (id) {
        await CurrenciesService.patch(id, data);
      } else {
        await CurrenciesService.create(data);
      }
      await PublishService.create({});
      setLoading(false);
      navigate('/dashboard/currencies/update');
    }
  };

  const handleAppImageChange = async ({ file }, status) => {
    if (status == 'done') {
      const base64File = await toBase64(file);
      const res = await UploadsService.create({ uri: base64File });
      setValue('appImage', res.id);
    } else if (status == 'removed') {
      const imageId = getValues('appImage');
      await UploadsService.remove(imageId);
    }
  };

  const handleWebImageChange = async ({ file }, status) => {
    if (status == 'done') {
      const base64File = await toBase64(file);
      const res = await UploadsService.create({ uri: base64File });
      setValue('webImage', res.id);
    } else if (status == 'removed') {
      const imageId = getValues('webImage');
      await UploadsService.remove(imageId);
    }
  };

  useEffect(() => {
    register('appImage');
    register('webImage');

    async function loadLocations() {
      setLoading(true);
      const {
        currenciesService,
        currencyLocationService,
        uploadsService,
        publishService,
      } = await import('@utils/feathers-connect');
      CurrenciesService = currenciesService;
      UploadsService = uploadsService;
      PublishService = publishService;

      const { total, data } = await currencyLocationService.find({});
      if (total) {
        setSiteLocationOptions(
          data
            .filter((item) => item.type === 'site' && item.id < 5)
            .map((item) => ({ value: item.id, title: item.title }))
        );
      }

      if (id > 0) {
        const currency = await currenciesService.get(id);
        if (!currency) {
          return;
        }
        setValue('title', currency.title);
        setValue('code', currency.code);
        setValue('symbol', currency.symbol);
        setValue('sell', parseFloat(currency.sell));
        setValue('buy', parseFloat(currency.buy));
        setValue('preSell', parseFloat(currency.preSell));
        setValue('preBuy', parseFloat(currency.preBuy));
        setValue('status', currency.status ? '1' : '0');
        setValue('siteLocation', currency.siteLocation);
        setValue('appLocation', currency.appLocation);
        setValue('isFeatured', currency.isFeatured);
        setValue('appImage', currency.appImage);
        setValue('webImage', currency.webImage);
        setWebImageId(currency.webImage);
        setAppImageId(currency.appImage);
      }
      setLoading(false);
    }
    isBrowser && loadLocations();
  }, [register]);

  return (
    <Layout>
      <SEO title="Currencies - new" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>{id ? 'تحديث العملة' : 'إضافة عملة جديدة'}</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      اسم العملة *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        name="title"
                        id="currency-title"
                        type="text"
                        placeholder="اسم العملة"
                        autoComplete="off"
                        hasError={hasError(errors, 'title')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-code"
                    >
                      رمز العملة *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="currency-code"
                        type="text"
                        placeholder="رمز العملة"
                        name="code"
                        autoComplete="off"
                        hasError={hasError(errors, 'code')}
                        ref={register({
                          required: ErrorMessages.required,
                          minLength: ErrorMessages.minLength(3),
                          validate: (val) =>
                            uniqueValueCheck(
                              SERVICES.currency,
                              'code',
                              val,
                              ErrorMessages.unique,
                              id
                            ),
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="code"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-symbol"
                    >
                      رمز العملة في التطبيق
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="currency-symbol"
                        type="text"
                        placeholder="رمز العملة في التطبيق"
                        name="symbol"
                        autoComplete="off"
                        hasError={hasError(errors, 'symbol')}
                        ref={register()}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="sell">
                      سعر البيع *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="sell"
                        name="sell"
                        type="text"
                        placeholder="سعر البيع"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'sell')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="sell"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="pre-sell"
                    >
                      سعر البيع السابق *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="pre-sell"
                        name="preSell"
                        type="text"
                        placeholder="سعر البيع السابق"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'preSell')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="preSell"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="buy">
                      سعر الشراء *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="buy"
                        name="buy"
                        type="text"
                        placeholder="سعر الشراء"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'buy')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="buy"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="pre-buy"
                    >
                      سعر الشراء السابق *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="pre-buy"
                        name="preBuy"
                        type="text"
                        placeholder="سعر الشراء السابق"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'preBuy')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="preBuy"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      ظهور العملة
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-visable"
                            type="radio"
                            name="status"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-visable">إظهار</label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-disable"
                            type="radio"
                            name="status"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-disable">إخفاء</label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="status"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      عملة مميز على الموقع
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="checkbox checkbox-primary">
                          <input
                            id="checkbox-featured"
                            type="checkbox"
                            name="isFeatured"
                            value="1"
                            ref={register}
                          />
                          <label htmlFor="checkbox-featured">تفعيل</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      موقع العملة على الويب و التطبيق
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <Select
                          id="site-location"
                          name="siteLocation"
                          hasError={hasError(errors, 'siteLocation')}
                          ref={register({
                            required: ErrorMessages.required,
                          })}
                        >
                          {siteLocationOptions.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.title}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          errors={errors}
                          name="siteLocation"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      صورة العملة للتطبيق
                    </label>
                    <div className="col-sm-9 dz-message needsclick">
                      <p>
                        ملاحظة: صورة التطبيق مطلوبة في حال كانت العملة ليست في
                        واجهة الدولار
                      </p>
                      <FilePreview
                        id={id}
                        imageId={appImageId}
                        onRemove={(imageId) =>
                          removeImage(
                            imageId,
                            'appImage',
                            setValue,
                            setAppImageId
                          )
                        }
                      />
                      <Dropzone
                        onChangeStatus={handleAppImageChange}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="اختر صورة"
                        styles={{
                          dropzone: { width: 350, height: 120 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="appImage"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      صورة العملة للموقع
                    </label>
                    <div className="col-sm-9 dz-message needsclick">
                      <FilePreview
                        id={id}
                        imageId={webImageId}
                        onRemove={(imageId) =>
                          removeImage(
                            imageId,
                            'webImage',
                            setValue,
                            setWebImageId
                          )
                        }
                      />
                      <Dropzone
                        onChangeStatus={handleWebImageChange}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="اختر صورة"
                        styles={{
                          dropzone: { width: 350, height: 120 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    {id ? 'تحديث' : 'حفظ'}
                  </button>
                  <Link
                    className="btn btn-light mr-1"
                    to={'/dashboard/currencies/update'}
                  >
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default CurrenciesNew;
