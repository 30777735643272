import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'gatsby';
import Layout from '@layouts/default';
import Preloader from '@components/preloader/index';
import { isBrowser } from '@utils/helper';
import Pagination from '@components/pagination/index';
import { RECORDS_PER_PAGE } from '@utils/consts';

let NotificationTemplatesService = () => {};

function getTitleType(type) {
  switch (type) {
    case 'news':
      return 'إخباري';
    case 'ads':
      return 'إعلاني';
    default:
      return type;
  }
}

const NotificationsTemplate = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [selectedIdTemplate, setSelectedIdTemplate] = useState(0);

  function confirmDeleteTemplate(id) {
    setShowConfirm(true);
    setSelectedIdTemplate(id);
  }

  async function deleteTemplate() {
    if (selectedIdTemplate > 0) {
      setLoading(true);
      await NotificationTemplatesService.remove(selectedIdTemplate);
      await loadTemplates(page);
      setShowConfirm(false);
      setLoading(false);
    }
  }

  async function loadTemplates(pageNum) {
    setLoading(true);
    const { total, data } = await NotificationTemplatesService.find({
      query: {
        $select: ['id', 'title', 'type', 'content', 'updatedAt'],
        $limit: RECORDS_PER_PAGE,
        $skip: (pageNum - 1) * RECORDS_PER_PAGE,
        $sort: { createdAt: -1 },
      },
    });
    let _totalPages = total / RECORDS_PER_PAGE;
    _totalPages += total % RECORDS_PER_PAGE ? 1 : 0;
    setTotalPages(_totalPages);
    setTemplates(data);
    setLoading(false);
    setPage(pageNum);
  }

  useEffect(() => {
    async function loadServices() {
      const { notificationTemplatesService } = await import(
        '@utils/feathers-connect'
      );
      NotificationTemplatesService = notificationTemplatesService;
      loadTemplates(page);
    }
    isBrowser && loadServices();
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>إدارة نماذج الاشعارات</h5>
              </div>
              <div className="card-block row">
                <div className="col-sm-12 col-lg-12 col-xl-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">{'#'}</th>
                          <th scope="col">{'اسم النموذج'}</th>
                          <th scope="col">{'نوع النموذج'}</th>
                          <th scope="col">{'نص النموذج'}</th>
                          <th scope="col">{'تاريخ التحديث'}</th>
                          <th scope="col">{'خيارات'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {templates.map((template) => (
                          <tr key={template.id}>
                            <th scope="row" className="num">
                              {template.id}
                            </th>
                            <td scope="row">
                              <Link
                                to={`/dashboard/notification-templates/edit/${template.id}`}
                              >
                                {template.title}
                              </Link>
                            </td>
                            <td scope="row">{getTitleType(template.type)}</td>
                            <td scope="row">
                              {template.content.substr(0, 30)}
                            </td>
                            <td scope="row" className="num">
                              {template.updatedAt}
                            </td>
                            <td scope="row" className="actions">
                              <Link
                                className="btn btn-secondary"
                                to={`/dashboard/notification-templates/edit/${template.id}`}
                              >
                                تعديل
                              </Link>
                              &nbsp;
                              {template.id !== 1 && (
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() =>
                                    confirmDeleteTemplate(template.id)
                                  }
                                >
                                  حذف
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div>
                      <br />
                      <nav className="m-b-30" aria-label="Page navigation">
                        <Pagination
                          page={page}
                          totalPages={totalPages}
                          onChange={(pageNum) => loadTemplates(pageNum)}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
      <Modal isOpen={isShowConfirm} toggle={() => setShowConfirm(false)}>
        <ModalHeader toggle={() => setShowConfirm(false)}>
          {'تأكيد'}
        </ModalHeader>
        <ModalBody>هل أنت متأكد من حذف هذا النموذج الاشعار؟</ModalBody>
        <ModalFooter>
          <Button type="button" color="warning" onClick={deleteTemplate}>
            تأكيد
          </Button>
          <Button
            type="button"
            color="light"
            onClick={() => setShowConfirm(false)}
          >
            إلغاء الأمر
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default NotificationsTemplate;
