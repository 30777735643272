import { debounce } from './helper';

const uniqueDebounceCheck = debounce(
  async (service, field, value, errorMessage, resolve, id) => {
    const {
      validateInputService
    } = await import('@utils/feathers-connect');
    const data = await validateInputService.find({
      query: {
        service,
        field,
        value,
        kind: 'unique',
        id
      },
    });

    if (data.result) {
      resolve(true);
    }

    resolve(errorMessage);
  },
  500
);

export const uniqueValueCheck = async (service, field, value, errorMessage, id) =>
  new Promise((resolve) => {
    uniqueDebounceCheck(service, field, value, errorMessage, resolve, id);
  });

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
