import styled from 'styled-components';

export const Preview = styled.div`
  background-color: #f1f1f1;
  border-radius: 1rem;
  display: flex;
  padding: 1rem;
  margin: 2rem 0 1rem 0;
  cursor: default;
  position: relative;
  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    border-radius: 1rem;
  }
  span {
    display: inline-block;
    overflow-x: hidden;
    max-width: 24rem;
    margin-right: 1rem;
  }
  button {
    position: absolute;
    background: transparent;
    border: 0;
    padding: 0;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: -1.5rem;
    right: -1.5rem;
    svg {
      width: 3rem;
      height: 3rem;
      circle {
        fill: #fff;
      }
    }
  }
`;
