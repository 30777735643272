import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Error, Input, Select } from '@components/styledComponents/index';
import axios from 'axios';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser } from '@utils/helper';
import { ErrorMessages } from '@utils/consts';

let CurrenciesService = () => {};
let PublishService = () => {};

const MaterialsNew = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [symbols, setSymbols] = useState([]);
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      data.type = 'material';
      data.siteLocation = 6; // Material site location
      data.appLocation = 10; // Material app location
      data.isFeatured = data.isFeatured === '1';
      data.buy = 0;
      data.preBuy = 0;

      // Add forex data
      const responseQuote = await axios.get(
        `https://api.1forge.com/quotes?pairs=${data.code}&api_key=${process.env.OneForgApIKey}`
      );
      const [item] = responseQuote.data;
      const oldSell = getValues('sell');
      data.sell = item.a;
      data.preSell = oldSell ? oldSell : item.p;

      if (id) {
        await CurrenciesService.patch(id, data);
      } else {
        await CurrenciesService.create(data);
      }
      await PublishService.create({});

      setLoading(false);
      navigate('/dashboard/materials/');
    }
  };

  useEffect(() => {
    register('sell');
    async function loadLocations() {
      setLoading(true);
      const { currenciesService, publishService } = await import(
        '@utils/feathers-connect'
      );
      CurrenciesService = currenciesService;
      PublishService = publishService;

      const localSymbols = localStorage.getItem('symbols');
      if (localSymbols) {
        setSymbols(JSON.parse(localSymbols));
      } else {
        const response = await axios.get(
          `https://api.1forge.com/symbols?api_key=${process.env.OneForgApIKey}`
        );
        localStorage.setItem('symbols', JSON.stringify(response.data.sort()));
        setSymbols(response.data);
      }

      if (id > 0) {
        const currency = await currenciesService.get(id);
        if (!currency) {
          return;
        }
        setValue('title', currency.title);
        setValue('code', currency.code);
        setValue('status', currency.status ? '1' : '0');
        setValue('isFeatured', currency.isFeatured);
        setValue('sell', parseFloat(currency.sell));
      }
      setLoading(false);
    }
    isBrowser && loadLocations();
  }, [register]);

  return (
    <Layout>
      <SEO title="Currencies - new" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>{id ? 'تحديث االنوع' : 'إضافة نوع جديد'}</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      الاسم *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        name="title"
                        id="currency-title"
                        type="text"
                        placeholder="الاسم"
                        autoComplete="off"
                        hasError={hasError(errors, 'title')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-code"
                    >
                      الرمز *
                    </label>
                    <div className="col-sm-9">
                      <Select
                        id="code-select"
                        name="code"
                        hasError={hasError(errors, 'code')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                        onChange={(e) =>
                          setValue('code', e.currentTarget.value)
                        }
                      >
                        {symbols.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage
                        errors={errors}
                        name="code"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      ظهور
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-visable"
                            type="radio"
                            name="status"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-visable">إظهار</label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-disable"
                            type="radio"
                            name="status"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-disable">إخفاء</label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="status"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      نوع مميز على الموقع
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="checkbox checkbox-primary">
                          <input
                            id="checkbox-featured"
                            type="checkbox"
                            name="isFeatured"
                            value="1"
                            ref={register}
                          />
                          <label htmlFor="checkbox-featured">تفعيل</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    {id ? 'تحديث' : 'حفظ'}
                  </button>
                  <Link
                    className="btn btn-light mr-1"
                    to={'/dashboard/materials/'}
                  >
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default MaterialsNew;
