import React, { Fragment, useEffect, useState } from 'react';
import Layout from '@layouts/default';
import {
  PreviewContainer,
  HeaderScreen,
  ScreenApp,
} from '@styledComponents/index';
import CoinBlock from '@components/coin-block/index';
import WideCoinBlock from '@components/coin-block/wide-block';
import Preloader from '@components/preloader/index';
import { COIN_TYPE, VALUE_STATUS } from '@components/coin-block/defines';
import { isBrowser } from '@utils/helper';
import { SiteLocations } from '@utils/consts';

const getStatusValue = (preValue, value) => {
  if (preValue > value) {
    return VALUE_STATUS.DOWN;
  } else if (preValue < value) {
    return VALUE_STATUS.UP;
  }
  return VALUE_STATUS.NONE;
};

const Currencies = () => {
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    async function loadCurrencies() {
      setLoading(true);
      const { currenciesService } = await import('@utils/feathers-connect');
      const { total, data } = await currenciesService.find({
        query: {
          status: 1,
          type: 'coin',
          $sort: {
            location: 1,
          },
          $limit: 400,
        },
      });
      console.log('total', total, data);
      if (total > 0) {
        setCurrencies(data);
      }
      setLoading(false);
    }
    isBrowser && loadCurrencies();
  }, []);
  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>إدارة أسعار العملات</h5>
              </div>
              <div className="card-body">
                <PreviewContainer>
                  <div>
                    <HeaderScreen>الدولار</HeaderScreen>
                    <ScreenApp>
                      {currencies
                        .filter(
                          (item) => item.siteLocation === SiteLocations.TRANSFER
                        )
                        .map((item) => (
                          <Fragment key={item.id}>
                            <CoinBlock
                              title={`مبيع ${item.title}`}
                              type={COIN_TYPE.BLUE}
                              symbol={item.symbol}
                              value={item.sell}
                              preValue={item.preSell}
                              percent={item.percentSell}
                              status={getStatusValue(item.preSell, item.sell)}
                            />
                            <CoinBlock
                              title={`شراء ${item.title}`}
                              type={COIN_TYPE.RED}
                              symbol={item.symbol}
                              value={item.buy}
                              preValue={item.preBuy}
                              percent={item.percentBuy}
                              status={getStatusValue(item.preBuy, item.buy)}
                            />
                          </Fragment>
                        ))}
                    </ScreenApp>
                  </div>
                  <div>
                    <HeaderScreen>العملات</HeaderScreen>
                    <ScreenApp>
                      {currencies
                        .filter(
                          (item) =>
                            item.siteLocation ===
                              SiteLocations.ARABIC_CURRENCIES ||
                            item.siteLocation ===
                              SiteLocations.FOREIGN_CURRENCIES
                        )
                        .map((item) => (
                          <WideCoinBlock
                            key={item.id}
                            imageUrl={`${process.env.UPLOADS_URL}${item.appImage}`}
                            sell={item.sell}
                            buy={item.buy}
                            preSell={item.preSell}
                            preBuy={item.preBuy}
                            percentSell={item.percentSell}
                            percentBuy={item.percentBuy}
                            statusSell={getStatusValue(item.preSell, item.sell)}
                            statusBuy={getStatusValue(item.preBuy, item.buy)}
                          />
                        ))}
                    </ScreenApp>
                  </div>
                  <div>
                    <HeaderScreen>المحافظات</HeaderScreen>
                    <ScreenApp>
                      {currencies
                        .filter(
                          (item) =>
                            item.siteLocation ===
                            SiteLocations.GOVERNORATE_CURRENCIES
                        )
                        .map((item) => (
                          <Fragment key={item.id}>
                            <CoinBlock
                              title={`مبيع ${item.title}`}
                              type={COIN_TYPE.BLUE}
                              symbol={item.symbol}
                              value={item.sell}
                              preValue={item.preSell}
                              percent={item.percentSell}
                              status={getStatusValue(item.preSell, item.sell)}
                            />
                            <CoinBlock
                              title={`شراء ${item.title}`}
                              type={COIN_TYPE.RED}
                              symbol={item.symbol}
                              value={item.buy}
                              preValue={item.preBuy}
                              percent={item.percentBuy}
                              status={getStatusValue(item.preBuy, item.buy)}
                            />
                          </Fragment>
                        ))}
                    </ScreenApp>
                  </div>
                </PreviewContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default Currencies;
