import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import 'moment/locale/ar';
import {} from 'react-feather';
import Layout from '@layouts/default';
import Preloader from '@components/preloader/index';
import { isBrowser } from '@utils/helper';
import Pagination from '@components/pagination/index';
import { RECORDS_PER_PAGE } from '@utils/consts';

let StocksService = () => {};
moment.locale('en');

const StocksAll = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  async function loadStocks(pageNum) {
    setLoading(true);
    const { total, data } = await StocksService.find({
      query: {
        $select: [
          'id',
          'stockCode',
          'stockName',
          'closePrice',
          'changePercent',
          'reference_id',
          'status',
          'updatedAt',
        ],
        $limit: RECORDS_PER_PAGE,
        $skip: (pageNum - 1) * RECORDS_PER_PAGE,
        $sort: { id: 1 },
      },
    });
    let _totalPages = total / RECORDS_PER_PAGE;
    _totalPages += total % RECORDS_PER_PAGE ? 1 : 0;
    setTotalPages(_totalPages);
    setStocks(data);
    setLoading(false);
    setPage(pageNum);
  }

  useEffect(() => {
    async function loadServices() {
      const { stocksService } = await import('@utils/feathers-connect');
      StocksService = stocksService;
      loadStocks(page);
    }
    isBrowser && loadServices();
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>الأسهم السورية</h5>
              </div>
              <div className="card-block row">
                <div className="col-sm-12 col-lg-12 col-xl-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">{'#'}</th>
                          <th scope="col">{'الرمز'}</th>
                          <th scope="col" style={{ maxWidth: '200px' }}>
                            {'اسم الشركة'}
                          </th>
                          <th scope="col">{'سعر الإغلاق'}</th>
                          <th scope="col">{'التغير'}</th>
                          <th scope="col">{'تفاصيل'}</th>
                          <th scope="col">{'تاريخ الانشاء'}</th>
                          <th scope="col">{'الحالة'}</th>
                          <th scope="col">{'خيارات'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stocks.map((item) => (
                          <tr key={item.id}>
                            <th scope="row" className="num">
                              {item.id}
                            </th>
                            <td scope="row" className="num">
                              {item.stockCode}
                            </td>
                            <td scope="row">{item.stockName}</td>
                            <td scope="row" className="num">
                              {item.closePrice}
                            </td>
                            <td scope="row" className="num">
                              {parseFloat(item.changePercent).toFixed(2)}%
                              &nbsp;
                              {item.changePercent > 0 && (
                                <i className="fa fa-arrow-up green" />
                              )}
                              {item.changePercent < 0 && (
                                <i className="fa fa-arrow-down red" />
                              )}
                              {item.changePercent == 0 && (
                                <i className="fa fa-minus orange" />
                              )}
                            </td>
                            <td scope="row" className="num">
                              <a
                                href={`http://www.dse.sy/issuer-company/view/${item.reference_id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-link"></i>
                                &nbsp;
                                تفاصيل السهم
                              </a>
                            </td>
                            <td scope="row" className="ltr num">
                              {moment(item.updatedAt).format(
                                'hh:mm A, Do MMMM YYYY'
                              )}
                            </td>
                            <td scope="row">
                              {item.status ? 'إظهار' : 'مخفي'}
                            </td>
                            <td scope="row" className="actions">
                              <Link
                                className="btn btn-secondary"
                                to={`/dashboard/stocks/edit/${item.id}`}
                              >
                                تعديل
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div>
                      <br />
                      <nav
                        className="m-b-30"
                        aria-label="Page navigation example"
                      >
                        <Pagination
                          page={page}
                          totalPages={totalPages}
                          onChange={(pageNum) => loadStocks(pageNum)}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default StocksAll;
