import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Layout from '@layouts/default';
import { Error } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { isBrowser } from '@utils/helper';
import { ErrorMessages } from '@utils/consts';

let SettingsService = () => {};

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const customAds = settings.find((item) => item.key == 'ads-custom');
      await SettingsService.patch(customAds.id, {
        value: data['ads-custom'],
      });

      const enableAds = settings.find((item) => item.key == 'ads-enable');
      await SettingsService.patch(enableAds.id, {
        value: data['ads-enable'],
      });

      setLoading(false);
      navigate('/dashboard/');
    }
  };

  useEffect(() => {
    async function prepareServices() {
      setLoading(true);
      const { settingsService } = await import('@utils/feathers-connect');
      SettingsService = settingsService;

      const response = await settingsService.find({});
      setSettings(response.data);

      if (response.total > 0) {
        const customAds = response.data.find(
          (item) => item.key == 'ads-custom'
        );
        setValue('ads-custom', customAds.value ? '1' : '0');

        const enableAds = response.data.find(
          (item) => item.key == 'ads-enable'
        );
        setValue('ads-enable', enableAds.value ? '1' : '0');
      }
      setLoading(false);
    }
    isBrowser && prepareServices();
  }, [register]);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>الإعدادات</h5>
                  <p>
                    ملاحظة: يقوم التطبيق بعرض إعلانات فيس بوك بشكل افتراضي, عند
                    تفعيل الإعلانات الخاصة يجب أن يكون هناك إعلان فعال بتاريخ
                    وصلاحية محددة في قسم الإعلانات.
                  </p>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      إظهار الإعلانات
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-ads-visable"
                            type="radio"
                            name="ads-enable"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-ads-visable">إظهار</label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-ads-disable"
                            type="radio"
                            name="ads-enable"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-ads-disable">إخفاء</label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="ads-enable"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      إظهار الإعلانات الخاصة
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-ads-custom-visable"
                            type="radio"
                            name="ads-custom"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-ads-custom-visable">
                            إظهار
                          </label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-ads-custom-disable"
                            type="radio"
                            name="ads-custom"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-ads-custom-disable">
                            إخفاء
                          </label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="ads-custom"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    تحديث
                  </button>
                  <Link className="btn btn-light mr-1" to={'/dashboard/'}>
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default Settings;
