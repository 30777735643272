import React, { useEffect, useState, Fragment } from 'react';
import Layout from '@layouts/default';
import {
  isBrowser,
  onlyNumberKey,
  prepareNotificationMessage,
} from '@utils/helper';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import Preloader from '@components/preloader/index';
import { Error } from '@components/styledComponents/index';
import { ErrorMessages, SiteLocations } from '@utils/consts';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';

let CurrenciesService = () => {};
let PatchCurrenciesService = () => {};
let PublishService = () => {};
let NotificationTemplatesService = () => {};
let PushNotificationService = () => {};

const CurrenciesUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [isShowSaved, setShowSaved] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rates, setRates] = useState([]);
  const [mainCurrency, setMainCurrency] = useState(null);
  const [isShowSynced, setShowSynced] = useState(false);
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    mode: 'onBlur',
  });

  function showConfirm() {
    setShowConfirm(true);
  }

  function saveAll() {
    setShowConfirm(false);
    handleSubmit(onSubmit)();
  }

  async function onSubmit(data) {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const values = [];
      currencies.forEach((currency) => {
        values.push({
          id: currency.id,
          sell: data[`${currency.id}-sell`],
          buy: data[`${currency.id}-buy`],
          preSell: currency.sell,
          preBuy: currency.buy,
        });
      });
      await PatchCurrenciesService.create(values);
      await sendNotification();
      setLoading(false);
    }
  }

  async function updateCurrency(id) {
    const sell = getValues(`${id}-sell`);
    const buy = getValues(`${id}-buy`);
    const currency = currencies.find((currency) => currency.id == id);
    if (!errors[`${id}-buy`] && !errors[`${id}-sell`]) {
      setLoading(true);
      await CurrenciesService.patch(id, {
        sell,
        buy,
        preSell: currency.sell,
        preBuy: currency.buy,
      });
      await PublishService.create({});
      setLoading(false);
      setShowSaved(true);
    } else {
      setErrorMessage('الرجاء ملئ الحقول المطلوبة');
      setShowError(true);
    }
  }

  async function sendNotification() {
    // Send notification
    // const template = await NotificationTemplatesService.get(1);

    const newPricesResult = await CurrenciesService.find({
      query: {
        status: 1,
        $select: ['id', 'title', 'code', 'buy', 'sell', 'preBuy', 'preSell'],
        $limit: 400,
      },
    });

    setCurrencies(newPricesResult.data);

    /* const contentNotifiy = prepareNotificationMessage(
      template.content,
      newPricesResult.data
    ); */

    // const topic = process.env.UpdateNotification;

    // console.log(contentNotifiy);
    /* await PushNotificationService.create(
      {
        to: `/topics/${topic}`,
        title: 'تحديث الأسعار',
        body: contentNotifiy,
        color: '#e84337',
      },
      {}
    ); */
  }

  useEffect(() => {
    async function loadCurrencies() {
      const {
        currenciesService,
        patchCurrenciesService,
        publishService,
        notificationTemplatesService,
        pushNotificationService,
      } = await import('@utils/feathers-connect');
      CurrenciesService = currenciesService;
      PatchCurrenciesService = patchCurrenciesService;
      PublishService = publishService;
      NotificationTemplatesService = notificationTemplatesService;
      PushNotificationService = pushNotificationService;

      const result = await currenciesService.find({
        query: {
          status: 1,
          type: 'coin',
          $select: [
            'id',
            'title',
            'code',
            'buy',
            'sell',
            'preBuy',
            'preSell',
            'siteLocation',
          ],
          $limit: 400,
        },
      });
      setCurrencies(result.data);
    }
    isBrowser && loadCurrencies();
  }, []);

  useEffect(() => {
    if (currencies.length > 0) get1ForgeRate();
  }, [currencies]);

  useEffect(() => {
    if (mainCurrency != null && rates.length > 0)
      autoSyncCurrency(mainCurrency);
  }, [rates, mainCurrency]);

  const get1ForgeRate = async () => {
    const pairs = await currencies
      .filter(
        (item) =>
          (item.siteLocation == SiteLocations.ARABIC_CURRENCIES ||
            item.siteLocation == SiteLocations.FOREIGN_CURRENCIES) &&
          item.code != 'Lp'
      )
      .map((item) => item.code + '/USD')
      .join(',');

    let response = await axios.get(
      `https://api.1forge.com/quotes?pairs=EUR/USD,${pairs}&api_key=${process.env.OneForgApIKey}`
    );

    await setRates(response.data);
  };

  const getCurrencyRate = (currency) => {
    if (currency.code == 'eursyr')
      return (rates.find(item => item.s.split('/')[0] == 'EUR').p);
    else
      return (rates.find(item => item.s.split('/')[0] == currency.code).p);
  };

  const autoSyncCurrency = async (currency) => {
    await currencies.map((item) => {
      if (
        (item.siteLocation == SiteLocations.ARABIC_CURRENCIES ||
          item.siteLocation == SiteLocations.FOREIGN_CURRENCIES ||
          item.code == 'eursyr') &&
        item.code != 'Lp'
      ) {
        setValue(
          `${item.id}-sell`,
          parseInt(getValues(`${currency.id}-sell`) * getCurrencyRate(item))
        );

        setValue(
          `${item.id}-buy`,
          parseInt(
            getValues(`${item.id}-sell`) -
              (getValues(`${item.id}-sell`) * 2) / 100
          )
        );
      }
    });

    setShowSynced(true);
    setTimeout(() => {
      setShowSynced(false);
      setMainCurrency(null);
    }, 2000);
  };

  async function saveForeignCurrency(data) {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const values = [];
      currencies.forEach((currency) => {
        if (
          (currency.siteLocation == SiteLocations.ARABIC_CURRENCIES ||
            currency.siteLocation == SiteLocations.FOREIGN_CURRENCIES || currency.code == 'eursyr') &&
          currency.code != 'Lp'
        )
          values.push({
            id: currency.id,
            sell: data[`${currency.id}-sell`],
            buy: data[`${currency.id}-buy`],
            preSell: currency.sell,
            preBuy: currency.buy,
          });
      });
      await PatchCurrenciesService.create(values);
      setLoading(false);
      setShowSaved(true);
    }
  }

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>تحديث أسعار العملات</h5>
                <br />
                <p>ملاحظة: إرسال الإشعار التلقائي يتم فقط بإختيار تحديث الكل</p>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {currencies.map((currency, index) => (
                    <Fragment key={currency.id}>
                      <h5>{currency.title}</h5>
                      <div
                        className="form-row"
                        style={
                          (currency.siteLocation == SiteLocations.ARABIC_CURRENCIES ||
                            currency.siteLocation == SiteLocations.FOREIGN_CURRENCIES ||
                            currency.code == 'eursyr') &&
                          currency.code != 'Lp'
                            ? {
                              backgroundColor: '#eeFFee',
                              padding: 10,
                              borderRadius: 5,
                            }
                            : {}
                        }
                      >
                        <div className="col-md-4 mb-12">
                          <label>سعر البيع *</label>
                          <input
                            className="form-control input-num"
                            name={`${currency.id}-sell`}
                            type="text"
                            placeholder="سعر البيع"
                            defaultValue={parseFloat(currency.sell)}
                            onKeyPress={onlyNumberKey}
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          ></input>
                          <ErrorMessage
                            errors={errors}
                            name="sell"
                            render={({ message }) => <Error>{message}</Error>}
                          />
                        </div>
                        <div className="col-md-4 mb-12">
                          <label>سعر الشراء *</label>
                          <input
                            className="form-control input-num"
                            name={`${currency.id}-buy`}
                            type="text"
                            placeholder="سعر الشراء"
                            defaultValue={parseFloat(currency.buy)}
                            onKeyPress={onlyNumberKey}
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          ></input>
                        </div>
                        <div className="col-md-4 mb-12 actions">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => updateCurrency(currency.id)}
                          >
                            تحديث
                          </button>
                          <Link
                            className="btn btn-info"
                            to={`/dashboard/currencies/edit/${currency.id}`}
                          >
                            تعديل التفاصيل
                          </Link>
                        </div>
                        {currency.code == 'USD2' && (
                          <>
                            <div
                              className="col-md-4"
                              style={{
                                backgroundColor: '#eee',
                                padding: 5,
                                margin: 5,
                                borderRadius: 5,
                              }}
                            >
                              <button
                                className="btn btn-success m-2"
                                type="button"
                                onClick={async () => {
                                  await get1ForgeRate();
                                  setMainCurrency(currency);
                                }}
                              >
                                مزامنة
                              </button>
                              <p className="text-secondary">
                                عند الضغط على مزامنة سيتم احتساب قيمة العملات
                                العربية والأجنبية المظللة بالأخضر بناءً على سعر
                                مبيع الدولار حلب.
                              </p>
                            </div>
                            <div
                              className="col-md-4"
                              style={{
                                backgroundColor: '#eee',
                                padding: 5,
                                margin: 5,
                                borderRadius: 5,
                              }}
                            >
                              <button
                                className="btn btn-success m-2"
                                type="button"
                                onClick={() => {
                                  updateCurrency(currency.id);
                                  handleSubmit(saveForeignCurrency)();
                                }}
                              >
                                تحديث
                              </button>
                              <p className="text-secondary">
                                عند الضغط على تحديث سيتم تحديث قيمة العملات
                                العربية والأجنبية فقط في قاعدة البيانات.
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                      {index < currencies.length - 1 && (
                        <hr className="mt-4 mb-4" />
                      )}
                    </Fragment>
                  ))}
                </form>
              </div>
              <div className="card-footer">
                <button
                  type="button"
                  className="btn btn-success mr-1"
                  onClick={showConfirm}
                >
                  تحديث الكل
                </button>
              </div>
              <Modal
                isOpen={isShowConfirm}
                toggle={() => setShowConfirm(false)}
              >
                <ModalHeader toggle={() => setShowConfirm(false)}>
                  {'تأكيد'}
                </ModalHeader>
                <ModalBody>
                  هل أنت متأكد من تحديث كل الأسعار وإرسال الاشعار
                </ModalBody>
                <ModalFooter>
                  <Button color="warning" onClick={saveAll}>
                    تأكيد
                  </Button>
                  <Button color="light" onClick={() => setShowConfirm(false)}>
                    إلغاء الأمر
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={isShowSaved} toggle={() => setShowSaved(false)}>
                <ModalHeader toggle={() => setShowSaved(false)}>
                  {'معلومة'}
                </ModalHeader>
                <ModalBody>تم حفظ البيانات بنجاح!</ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowSaved(false)}>
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={isShowSynced} toggle={() => setShowSynced(false)}>
                <ModalHeader toggle={() => setShowSynced(false)}>
                  {'معلومة'}
                </ModalHeader>
                <ModalBody>
                  تم مزامنة العملات العربية والأجنبية بنجاح!
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowSynced(false)}>
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={isShowError} toggle={() => setShowError(false)}>
                <ModalHeader toggle={() => setShowSaved(false)}>
                  {'خطأ'}
                </ModalHeader>
                <ModalBody>{errorMessage}</ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowError(false)}>
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default CurrenciesUpdate;
