import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import FilePreview from '@components/file-preview/index';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Error, Input } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser } from '@utils/helper';
import { ErrorMessages } from '@utils/consts';
import { toBase64 } from '@utils/formHelper';

let StocksService = () => {};
let UploadsService = () => {};
let UpdateStocksService = () => {};

async function removeImage(imageId, field, setValue, setImageId) {
  await UploadsService.remove(imageId);
  setValue(field, null);
  setImageId(null);
}

const CurrenciesNew = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState();
  const [appImageId, setAppImageId] = useState();
  const [webImageId, setWebImageId] = useState();
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      if (id) {
        await StocksService.patch(id, data);
      } else {
        await StocksService.create(data);
      }
      await UpdateStocksService.create({});
      setLoading(false);
      navigate('/dashboard/stocks');
    }
  };

  const handleAppImageChange = async ({ file }, status) => {
    if (status == 'done') {
      const base64File = await toBase64(file);
      const res = await UploadsService.create({ uri: base64File });
      setValue('appImage', res.id);
    } else if (status == 'removed') {
      const imageId = getValues('appImage');
      await UploadsService.remove(imageId);
    }
  };

  const handleWebImageChange = async ({ file }, status) => {
    if (status == 'done') {
      const base64File = await toBase64(file);
      const res = await UploadsService.create({ uri: base64File });
      setValue('webImage', res.id);
    } else if (status == 'removed') {
      const imageId = getValues('webImage');
      await UploadsService.remove(imageId);
    }
  };

  useEffect(() => {
    register('appImage');
    register('webImage');

    async function loadStock() {
      setLoading(true);
      const {
        stocksService,
        uploadsService,
        updateStocksService,
      } = await import('@utils/feathers-connect');
      StocksService = stocksService;
      UploadsService = uploadsService;
      UpdateStocksService = updateStocksService;

      if (!id) {
        navigate('/dashboard/stocks');
      }

      const stock = await stocksService.get(id);
      if (!stock) {
        return;
      }
      setCode(stock.stockCode);
      setValue('stockName', stock.stockName);
      setValue('status', stock.status ? '1' : '0');
      setValue('appImage', stock.appImage);
      setValue('webImage', stock.webImage);
      setWebImageId(stock.webImage);
      setAppImageId(stock.appImage);
      setLoading(false);
    }
    isBrowser && loadStock();
  }, [register]);

  return (
    <Layout>
      <SEO title="Stock - update" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>تحديث السهم</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      رمز السهم
                    </label>
                    <div className="col-sm-9">{code}</div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      اسم السهم *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        name="stockName"
                        id="stock-name"
                        type="text"
                        placeholder="اسم السهم"
                        autoComplete="off"
                        hasError={hasError(errors, 'stockName')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="stockName"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      ظهور السهم
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-visable"
                            type="radio"
                            name="status"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-visable">إظهار</label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-disable"
                            type="radio"
                            name="status"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-disable">إخفاء</label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="status"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      صورة السهم للتطبيق
                    </label>
                    <div className="col-sm-9 dz-message needsclick">
                      <FilePreview
                        id={id}
                        imageId={appImageId}
                        onRemove={(imageId) =>
                          removeImage(
                            imageId,
                            'appImage',
                            setValue,
                            setAppImageId
                          )
                        }
                      />
                      <Dropzone
                        onChangeStatus={handleAppImageChange}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="اختر صورة"
                        styles={{
                          dropzone: { width: 350, height: 120 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="appImage"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      صورة السهم للموقع
                    </label>
                    <div className="col-sm-9 dz-message needsclick">
                      <FilePreview
                        id={id}
                        imageId={webImageId}
                        onRemove={(imageId) =>
                          removeImage(
                            imageId,
                            'webImage',
                            setValue,
                            setWebImageId
                          )
                        }
                      />
                      <Dropzone
                        onChangeStatus={handleWebImageChange}
                        maxFiles={1}
                        multiple={false}
                        canCancel={false}
                        inputContent="اختر صورة"
                        styles={{
                          dropzone: { width: 350, height: 120 },
                          dropzoneActive: { borderColor: 'green' },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    تحديث
                  </button>
                  <Link className="btn btn-light mr-1" to={'/dashboard/stocks'}>
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default CurrenciesNew;
