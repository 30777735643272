import React from 'react';
import {
  Pagination as Container,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

function renderPages(total, page, onChange) {
  const items = [];
  for (let i = 0; i < total - 1; i++) {
    items.push(
      <PaginationItem key={i} active={i + 1 == page}>
        <PaginationLink
          onClick={(e) => {
            e.preventDefault();
            onChange(i + 1);
          }}
        >
          {i + 1}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return items;
}

const Pagination = ({ page, totalPages, onChange }) => {
  if (totalPages < 2) {
    return <></>;
  }

  return (
    <Container
      aria-label="Page navigation"
      className="pagination justify-content-center pagination-primary"
    >
      {page > 1 && (
        <PaginationItem>
          <PaginationLink
            onClick={(e) => {
              e.preventDefault();
              onChange(page - 1);
            }}
          >
            {'السابق'}
          </PaginationLink>
        </PaginationItem>
      )}
      {renderPages(totalPages, page, onChange)}
      {page < totalPages && (
        <PaginationItem>
          <PaginationLink
            last
            onClick={(e) => {
              e.preventDefault();
              onChange(page + 1);
            }}
          >
            {'التالي'}
          </PaginationLink>
        </PaginationItem>
      )}
    </Container>
  );
};

export default Pagination;
