import React, { useEffect, useState, Fragment } from 'react';
import Layout from '@layouts/default';
import { isBrowser, onlyNumberKey } from '@utils/helper';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import Preloader from '@components/preloader/index';
import { Error } from '@components/styledComponents/index';
import { ErrorMessages } from '@utils/consts';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
let CurrenciesService = () => {};
let PatchCurrenciesService = () => {};
let PublishService = () => {};

const GoldUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [aleppoUSDPrice, setAleppoUSDPrice] = useState({});
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [isShowSaved, setShowSaved] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowSynced, setShowSynced] = useState(false);
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    mode: 'onBlur',
  });

  function showConfirm() {
    setShowConfirm(true);
  }

  function saveAll() {
    setShowConfirm(false);
    handleSubmit(onSubmit)();
  }

  async function onSubmit(data) {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const values = [];
      currencies.forEach((currency) => {
        values.push({
          id: currency.id,
          sell: data[`${currency.id}-sell`],
          preSell: currency.sell,
          buy: 0,
          preBuy: 0,
        });
      });
      await PatchCurrenciesService.create(values);
      setLoading(false);
    }
  }

  async function updateCurrency(id) {
    const sell = getValues(`${id}-sell`);
    const currency = currencies.find((currency) => currency.id == id);
    if (!errors[`${id}-buy`] && !errors[`${id}-sell`]) {
      setLoading(true);
      await CurrenciesService.patch(id, {
        sell,
        preSell: currency.sell,
        buy: 0,
        preBuy: 0,
      });
      await PublishService.create({});
      setLoading(false);
      setShowSaved(true);
    } else {
      setErrorMessage('الرجاء ملئ الحقول المطلوبة');
      setShowError(true);
    }
  }

  useEffect(() => {
    async function loadGolds() {
      const {
        currenciesService,
        patchCurrenciesService,
        publishService,
      } = await import('@utils/feathers-connect');
      CurrenciesService = currenciesService;
      PatchCurrenciesService = patchCurrenciesService;
      PublishService = publishService;

      const result = await currenciesService.find({
        query: {
          status: 1,
          type: 'gold',
          $select: ['id', 'title', 'sell', 'preSell', 'code'],
          $limit: 400,
        },
      });
      const AleppoResults = await currenciesService.find({
        query: {
          status: 1,
          code: 'USD2',
          $select: ['id', 'sell', 'code'],
          $limit: 1,
        },
      });
      setAleppoUSDPrice(AleppoResults.data[0])
      setCurrencies(result.data);
    }
    isBrowser && loadGolds();
  }, []);

  //Helper to get the id field of Syria 21 price
  const getSyria21InputId = () => {
    return (currencies.find(currency => currency.code == 'Gold21').id)
  }

  // Get Forex price of gold in US, Europe, Sweden and Turkey and auto update prices
  const get1ForgeRateAndSync = async () => {
    let response = await axios.get(
      `https://api.1forge.com/quotes?pairs=XAU/USD,XAU/EUR,XAU/SEK,XAU/TRY&api_key=${process.env.OneForgApIKey}`
    );

    let rates = response.data;

    currencies.map(currency => {
      switch (currency.code) {
        // Euro Gold 21
        case 'Xaueur':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/EUR').p / 31.1 / 24 * 21).toFixed());
          break;

        // Sweden Gold 21
        case 'Xaukron':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/SEK').p / 31.1 / 24 * 21).toFixed());
          break;

        // TRY Gold 21
        case 'Xautry':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/TRY').p / 31.1 / 24 * 21).toFixed());
          break;

        // TRY Gold 22
        case 'Xautry22':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/TRY').p / 31.1 / 24 * 22).toFixed());
          break;

        // TRY Gold 18
        case 'Xautry18':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/TRY').p / 31.1 / 24 * 18).toFixed());
          break;

        // TRY Gold 14
        case 'Xautry14':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/TRY').p / 31.1 / 24 * 14).toFixed());
          break;

        // TRY Gold 24
        case 'Xautry24':
          setValue(`${currency.id}-sell`, (rates.find(currency => currency.s == 'XAU/TRY').p / 31.1).toFixed());
          break;

        //Free 21
        case 'Xauspy':
          setValue(`${currency.id}-sell`, ((rates.find(currency => currency.s == 'XAU/USD').p / 31.1 / 24 * 21) * aleppoUSDPrice.sell).toFixed());
          break;

        //Calculated by us dollar equal to:
        case 'Goldsy':
          setValue(`${currency.id}-sell`, (getValues(`${getSyria21InputId()}-sell`) / (rates.find(currency => currency.s == 'XAU/USD').p / 31.1 / 24 * 21)).toFixed());
          break;

        default:
          break;
      }
    })
  };


  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>تحديث أسعار الذهب</h5>
              </div>
              <div className="form-row p-4">
                <div className="col-md-4" style={{ backgroundColor: "#eee", padding: 5, margin: 5, borderRadius: 5 }}>
                  <button
                    className="btn btn-success m-2"
                    type="button"
                    onClick={async () => {
                      await get1ForgeRateAndSync();
                      setShowSynced(true);
                    }}
                  >
                    مزامنة
                  </button>
                  <p className="text-secondary">عند الضغط على مزامنة سيتم حساب قيمة الذهب في تركيا 14,18,21,22,24 ,الذهب باليورو ,الذهب بالكرون السويدي ,الذهب الحر ومحسوب على دولار.</p>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {currencies.map((currency, index) => (
                    <Fragment key={currency.id}>
                      <h5>{currency.title}</h5>
                      <div className="form-row">
                        <div className="col-md-4 mb-12">
                          <label>سعر البيع *</label>
                          <input
                            className="form-control input-num"
                            name={`${currency.id}-sell`}
                            type="text"
                            placeholder="سعر البيع"
                            defaultValue={parseFloat(currency.sell)}
                            onKeyPress={onlyNumberKey}
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          ></input>
                          <ErrorMessage
                            errors={errors}
                            name="sell"
                            render={({ message }) => <Error>{message}</Error>}
                          />
                        </div>
                        <div className="col-md-4 mb-12 actions">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => updateCurrency(currency.id)}
                          >
                            تحديث
                          </button>
                          <Link
                            className="btn btn-info"
                            to={`/dashboard/gold/edit/${currency.id}`}
                          >
                            تعديل التفاصيل
                          </Link>
                        </div>
                      </div>
                      {index < currencies.length - 1 && (
                        <hr className="mt-4 mb-4" />
                      )}
                    </Fragment>
                  ))}
                </form>
              </div>
              <div className="card-footer">
                <button
                  type="button"
                  className="btn btn-success mr-1"
                  onClick={showConfirm}
                >
                  تحديث الكل
                </button>
              </div>
              <Modal
                isOpen={isShowConfirm}
                toggle={() => setShowConfirm(false)}
              >
                <ModalHeader toggle={() => setShowConfirm(false)}>
                  {'تأكيد'}
                </ModalHeader>
                <ModalBody>هل أنت متأكد من تحديث كل الأسعار</ModalBody>
                <ModalFooter>
                  <Button color="warning" onClick={saveAll}>
                    تأكيد
                  </Button>
                  <Button color="light" onClick={() => setShowConfirm(false)}>
                    إلغاء الأمر
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={isShowSaved} toggle={() => setShowSaved(false)}>
                <ModalHeader toggle={() => setShowSaved(false)}>
                  {'معلومة'}
                </ModalHeader>
                <ModalBody>تم حفظ البيانات بنجاح!</ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowSaved(false)}>
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={isShowSynced} toggle={() => setShowSynced(false)}>
                <ModalHeader toggle={() => setShowSynced(false)}>
                  {'معلومة'}
                </ModalHeader>
                <ModalBody>
                  تم مزامنة أسعار الذهب المذكورة بنجاح!
                  لحفظ الأسعار الجديدة يرجى الضغط على تحديث الكل.
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowSynced(false)}>
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal isOpen={isShowError} toggle={() => setShowError(false)}>
                <ModalHeader toggle={() => setShowSaved(false)}>
                  {'خطأ'}
                </ModalHeader>
                <ModalBody>{errorMessage}</ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={() => setShowError(false)}>
                    إغلاق
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default GoldUpdate;