import React, { useEffect, useState } from 'react';
import Layout from '@layouts/default';
import {
  PreviewContainer,
  HeaderScreen,
  ScreenApp,
} from '@styledComponents/index';
import CoinBlock from '@components/coin-block/index';
import Preloader from '@components/preloader/index';
import { COIN_TYPE, VALUE_STATUS } from '@components/coin-block/defines';
import { isBrowser } from '@utils/helper';

const getStatusValue = (preValue, value) => {
  if (preValue > value) {
    return VALUE_STATUS.DOWN;
  } else if (preValue < value) {
    return VALUE_STATUS.UP;
  }
  return VALUE_STATUS.NONE;
};

const Materials = () => {
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    async function loadCurrencies() {
      setLoading(true);
      const { currenciesService } = await import('@utils/feathers-connect');
      const { total, data } = await currenciesService.find({
        query: {
          status: 1,
          type: 'material',
          $sort: {
            location: 1,
          },
        },
      });
      if (total > 0) {
        setCurrencies(data);
      }
      setLoading(false);
    }
    isBrowser && loadCurrencies();
  }, []);
  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>إدارة أسعار البورصة</h5>
              </div>
              <div className="card-body">
                <PreviewContainer>
                  <div>
                    <HeaderScreen>البورصات</HeaderScreen>
                    <ScreenApp>
                      {currencies.map((item) => (
                        <CoinBlock
                          key={item.id}
                          title={item.title}
                          type={COIN_TYPE.BLACK}
                          symbol={item.symbol}
                          value={item.sell}
                          preValue={item.preSell}
                          percent={item.percentSell}
                          status={getStatusValue(item.preSell, item.sell)}
                        />
                      ))}
                    </ScreenApp>
                  </div>
                </PreviewContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default Materials;
