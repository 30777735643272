import styled from 'styled-components';

export const TagsContainer = styled.div`
  width: 24rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
`;

export const NotificationPreview = styled.p`
  padding: 1rem 0.6rem;
  background-color: #CCC;
  font-family: 'Droid Arabic Kufi';
  font-size: 1.4rem;
  border-radius: 0.8rem;
`;
