import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Layout from '@layouts/default';
import { Error, Input, Select, Desc } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser, prepareNotificationMessage } from '@utils/helper';
import { ErrorMessages, SERVICES, AppScreensList } from '@utils/consts';
import { TagsContainer } from './styles';
import { uniqueValueCheck } from '@utils/formHelper';

let NotificationsTemplateService = () => {};
let StocksService = () => {};

const NotificationTempalteNew = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedStock, setSelectedStock] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [stocks, setStocks] = useState([]);
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      if (id) {
        await NotificationsTemplateService.patch(id, data);
      } else {
        await NotificationsTemplateService.create(data);
      }
      setLoading(false);
      navigate('/dashboard/notification-templates');
    }
  };

  const addCurrency = () => {
    let content = getValues('content');
    content += `#${selectedCurrency}#`;
    setValue('content', content);
  };

  const addStock = () => {
    let content = getValues('content');
    content += `#${selectedStock}#`;
    setValue('content', content);
  };

  const loadStocks = async () => {
    const { total, data } = await StocksService.find({
      query: {
        status: 1,
        $select: ['id', 'stockCode', 'stockName', 'closePrice'],
        $sort: { id: 1 },
      },
    });
    if (total) {
      setSelectedStock(data[0].stockCode);
      setStocks(data);
    }
  };

  useEffect(() => {
    async function loadLocations() {
      setLoading(true);
      const {
        notificationTemplatesService,
        currenciesService,
        stocksService,
      } = await import('@utils/feathers-connect');
      NotificationsTemplateService = notificationTemplatesService;
      StocksService = stocksService;

      loadStocks();
      const { total, data } = await currenciesService.find({
        query: {
          $select: ['id', 'title', 'code'],
          status: 1,
          $limit: 400,
        },
      });

      if (total) {
        setCurrencies(
          data.map((item) => ({
            id: item.id,
            value: item.code,
            title: item.title,
          }))
        );
        setSelectedCurrency(data[0].code);
      }

      if (id > 0) {
        const template = await notificationTemplatesService.get(id);
        if (!template) {
          return;
        }
        setValue('title', template.title);
        setValue('type', template.type);
        setValue('content', template.content);
        setValue('landingScreen', template.landingScreen);
      }
      setLoading(false);
    }
    isBrowser && loadLocations();
  }, [register]);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>{id > 0 ? 'تعديل نموذج' : 'نموذج اشعار جديد'}</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="template-name"
                    >
                      اسم النموذج *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        name="title"
                        id="template-title"
                        type="text"
                        placeholder="اسم النموذج"
                        autoComplete="off"
                        hasError={hasError(errors, 'title')}
                        ref={register({
                          required: ErrorMessages.required,
                          validate: (val) =>
                            uniqueValueCheck(
                              SERVICES.notificationTemplates,
                              'title',
                              val,
                              ErrorMessages.unique,
                              id
                            ),
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      نوع قناة الاشعار
                    </label>
                    <div className="col-sm-9">
                      <Select
                        id="template-type"
                        name="type"
                        hasError={hasError(errors, 'type')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      >
                        <option value="news">إخباري</option>
                        <option value="ads">إعلاني</option>
                      </Select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      الشاشة الوجهة
                    </label>
                    <div className="col-sm-9">
                      <select
                        hasError={hasError(errors, 'landingScreen')}
                        name="landingScreen"
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      >
                        {AppScreensList.map((item) => (
                          <option key={item.id} value={item.value}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="landingScreen"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      النص *
                    </label>
                    <div className="col-sm-9">
                      <TagsContainer>
                        <span>إضافة عملة</span>
                        &nbsp;&nbsp;
                        <Select
                          id="currency-dropdown"
                          name="currency"
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                          defaultValue={selectedCurrency}
                        >
                          {currencies.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.title}
                            </option>
                          ))}
                        </Select>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={addCurrency}
                        >
                          أضف
                        </button>
                      </TagsContainer>

                      <TagsContainer>
                        <span>إضافة سهم</span>
                        &nbsp;&nbsp;
                        <Select
                          id="stocks-dropdown"
                          name="stock"
                          onChange={(e) => setSelectedStock(e.target.value)}
                          defaultValue={selectedStock}
                        >
                          {stocks.map((item) => (
                            <option key={item.stockCode} value={item.stockCode}>
                              {item.stockName}
                            </option>
                          ))}
                        </Select>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={addStock}
                        >
                          أضف
                        </button>
                      </TagsContainer>

                      <Desc
                        className="form-control"
                        id="template-content"
                        name="content"
                        placeholder="نص الاشعار"
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="content"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary mr-1">
                    {id ? 'تحديث' : 'حفظ'}
                  </button>
                  <Link
                    className="btn btn-light mr-1"
                    to={'/dashboard/notification-templates'}
                  >
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default NotificationTempalteNew;
