import React from 'react';
import {
  Title,
  Value,
  PreValue,
  ChangeArrow,
  WideBox,
  Flag,
  Details,
  Row,
  ChangePercent
} from './styles';

const WideCoinBlock = (props) => {
  return (
    <WideBox>
      <Flag>
        <img src={props.imageUrl} />
      </Flag>
      <Details>
        <Row>
          <div>
            <PreValue>{parseFloat(props.preBuy)}</PreValue>
            <ChangePercent>{parseFloat(props.percentBuy)}%</ChangePercent>
          </div>
          <ChangeArrow type={props.statusBuy} />
          <Value>{parseFloat(props.buy)}</Value>
          <Title>{'شراء'}</Title>
        </Row>
        <Row>
          <div>
            <PreValue>{parseFloat(props.preSell)}</PreValue>
            <ChangePercent>{parseFloat(props.percentSell)}%</ChangePercent>
          </div>
          <ChangeArrow type={props.statusSell} />
          <Value>{parseFloat(props.sell)}</Value>
          <Title>{'مبيع'}</Title>
        </Row>
      </Details>
    </WideBox>
  );
};

export default WideCoinBlock;
