import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Error, Input } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser, onlyNumberKey } from '@utils/helper';
import { ErrorMessages, SERVICES } from '@utils/consts';
import { uniqueValueCheck } from '@utils/formHelper';

let CurrenciesService = () => {};
let PublishService = () => {};

const GoldNew = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      data.type = 'gold';
      data.siteLocation = 5; // Gold location
      data.appLocation = 9; // Gold location
      data.isFeatured = data.isFeatured === '1';
      if (id) {
        await CurrenciesService.patch(id, data);
      } else {
        await CurrenciesService.create(data);
      }
      await PublishService.create({});
      setLoading(false);
      navigate('/dashboard/gold/update');
    }
  };

  useEffect(() => {
    async function loadLocations() {
      setLoading(true);
      const { currenciesService, publishService } = await import(
        '@utils/feathers-connect'
      );
      CurrenciesService = currenciesService;
      PublishService = publishService;

      if (id > 0) {
        const currency = await currenciesService.get(id);
        if (!currency) {
          return;
        }
        setValue('title', currency.title);
        setValue('code', currency.code);
        setValue('sell', parseFloat(currency.sell));
        setValue('buy', parseFloat(currency.buy));
        setValue('preSell', parseFloat(currency.preSell));
        setValue('preBuy', parseFloat(currency.preBuy));
        setValue('status', currency.status ? '1' : '0');
        setValue('isFeatured', currency.isFeatured);
      }
      setLoading(false);
    }
    isBrowser && loadLocations();
  }, [register]);

  return (
    <Layout>
      <SEO title="Currencies - new" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>{id ? 'تحديث نوع الذهب' : 'إضافة نوع ذهب جديد'}</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      الاسم *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        name="title"
                        id="currency-title"
                        type="text"
                        placeholder="الاسم"
                        autoComplete="off"
                        hasError={hasError(errors, 'title')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-code"
                    >
                      رمز الذهب *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="currency-code"
                        type="text"
                        placeholder="رمز الذهب"
                        name="code"
                        autoComplete="off"
                        hasError={hasError(errors, 'code')}
                        ref={register({
                          required: ErrorMessages.required,
                          minLength: ErrorMessages.minLength(3),
                          validate: (val) =>
                            uniqueValueCheck(
                              SERVICES.currency,
                              'code',
                              val,
                              ErrorMessages.unique,
                              id
                            ),
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="code"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="sell">
                      سعر البيع *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="sell"
                        name="sell"
                        type="text"
                        placeholder="سعر البيع"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'sell')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="sell"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="pre-sell"
                    >
                      سعر البيع السابق *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="pre-sell"
                        name="preSell"
                        type="text"
                        placeholder="سعر البيع السابق"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'preSell')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="preSell"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      ظهور
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-visable"
                            type="radio"
                            name="status"
                            value="1"
                            defaultChecked
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-visable">إظهار</label>
                        </div>
                        <div className="radio radio-primary radio-inline">
                          <input
                            id="radio-status-disable"
                            type="radio"
                            name="status"
                            value="0"
                            ref={register({
                              required: ErrorMessages.required,
                            })}
                          />
                          <label htmlFor="radio-status-disable">إخفاء</label>
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="status"
                          render={({ message }) => <Error>{message}</Error>}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      نوع مميز على الموقع
                    </label>
                    <div className="col-sm-9">
                      <div className="form-group">
                        <div className="checkbox checkbox-primary">
                          <input
                            id="checkbox-featured"
                            type="checkbox"
                            name="isFeatured"
                            value="1"
                            ref={register}
                          />
                          <label htmlFor="checkbox-featured">تفعيل</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    {id ? 'تحديث' : 'حفظ'}
                  </button>
                  <Link
                    className="btn btn-light mr-1"
                    to={'/dashboard/gold/update'}
                  >
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default GoldNew;
