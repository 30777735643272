import React from 'react';
import {
  Preview,
} from './styles';
import { XCircle } from 'react-feather';

const FilePreview = ({ id, imageId, onRemove }) => {
  if (!id || !imageId) {
    return <></>;
  }

  return (
    <Preview>
      <button
        type="button"
        onClick={() => onRemove(imageId)}
      >
        <XCircle />
      </button>
      <img src={`${process.env.UPLOADS_URL}${imageId}`} />
      <span>{imageId}</span>
    </Preview>
  );
};

export default FilePreview;
