import React from 'react';
import { withPrefix } from 'gatsby';
import { Router } from '@reach/router';
import SecureRoute from '@components/secure-route';
import Dashboard from '@components/pages/dashboard';
import CurrenciesPreview from '@components/pages/currencies/index';
import CurrenciesAll from '@components/pages/currencies-all/index';
import NewCurrencies from '@components/pages/currencies-new/index';
import UpdateCurrencies from '@components/pages/currencies-update/index';
import HistoryCurrencies from '@components/pages/currencies-history/index';
import GoldPreview from '@components/pages/gold/index';
import GoldAll from '@components/pages/gold-all/index';
import NewGold from '@components/pages/gold-new/index';
import UpdateGold from '@components/pages/gold-update/index';
import HistoryGold from '@components/pages/gold-history/index';
import Materials from '@components/pages/materials/index';
import MaterialsAll from '@components/pages/materials-all/index';
import NewMaterials from '@components/pages/materials-new/index';
import News from '@components/pages/news/index';
import NewNews from '@components/pages/news-new/index';
import NewNotification from '@components/pages/notification-new/index';
import NotificationTemplates from '@components/pages/notification-template/index';
import NotificationTemplateNew from '@components/pages/notification-template-new/index';
import HistoryEdit from '@components/pages/history-edit/index';
import Ads from '@components/pages/ads/index';
import NewAds from '@components/pages/ads-new/index';
import StocksAll from '@components/pages/stocks-all/index';
import UpdateStock from '@components/pages/stock-update/index';
import Settings from '@components/pages/settings/index';

const DashboardPage = (props) => {
  return (
    <Router basepath={withPrefix('/')}>
      <SecureRoute
        default
        path="dashboard/"
        component={Dashboard}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/currencies"
        component={CurrenciesPreview}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/currencies/all"
        component={CurrenciesAll}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/currencies/new"
        component={NewCurrencies}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/currencies/edit/:id"
        component={NewCurrencies}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/currencies/update"
        component={UpdateCurrencies}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/history/currencies"
        component={HistoryCurrencies}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/gold"
        component={GoldPreview}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/gold/all"
        component={GoldAll}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/gold/update"
        component={UpdateGold}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/gold/new"
        component={NewGold}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/gold/edit/:id"
        component={NewGold}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/history/gold"
        component={HistoryGold}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/materials/"
        component={Materials}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/materials/all"
        component={MaterialsAll}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/materials/new"
        component={NewMaterials}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/materials/edit/:id"
        component={NewMaterials}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/news"
        component={News}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/news/new"
        component={NewNews}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/news/edit/:id"
        component={NewNews}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/notifications/send"
        component={NewNotification}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/notification-templates"
        component={NotificationTemplates}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/notification-templates/new"
        component={NotificationTemplateNew}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/notification-templates/edit/:id"
        component={NotificationTemplateNew}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/history/edit/:type/:id"
        component={HistoryEdit}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/ads"
        component={Ads}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/ads/new"
        component={NewAds}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/ads/edit/:id"
        component={NewAds}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/stocks"
        component={StocksAll}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/stocks/edit/:id"
        component={UpdateStock}
        location={props.location}
      />
      <SecureRoute
        path="dashboard/settings"
        component={Settings}
        location={props.location}
      />
    </Router>
  );
};

export default DashboardPage;
