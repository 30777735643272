import React, { useEffect, useState } from 'react';
import Layout from '@layouts/default';
import { Link } from 'gatsby';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ar';
import Preloader from '@components/preloader/index';
import { isBrowser } from '@utils/helper';
import Pagination from '@components/pagination/index';
import { RECORDS_PER_PAGE } from '@utils/consts';

let HistoryService = () => {};

const CurrenciesHistory = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [selectedIdHistory, setSelectedIdHistory] = useState(0);
  const [currency, setCurrency] = useState(0);
  const [currencies, setCurrencies] = useState([]);

  function confirmDeleteHistory(id) {
    setShowConfirm(true);
    setSelectedIdHistory(id);
  }

  async function deleteHistory() {
    if (selectedIdHistory > 0) {
      setLoading(true);
      await HistoryService.remove(selectedIdHistory);
      await loadHistory(page, currency);
      setShowConfirm(false);
      setLoading(false);
    }
  }

  async function loadHistory(pageNum, currencyId) {
    setLoading(true);
    const { total, data } = await HistoryService.find({
      query: {
        $select: ['id', 'sell', 'buy', 'updatedAt'],
        $limit: RECORDS_PER_PAGE,
        $skip: (pageNum - 1) * RECORDS_PER_PAGE,
        $sort: { createdAt: -1 },
        currencyId,
      },
    });
    let _totalPages = total / RECORDS_PER_PAGE;
    _totalPages += total % RECORDS_PER_PAGE ? 1 : 0;
    setTotalPages(_totalPages);
    setHistory(data);
    setLoading(false);
    setPage(pageNum);
  }

  function updateCurrency(id) {
    setCurrency(id);
    setPage(1);
    loadHistory(1, id);
  }

  useEffect(() => {
    async function loadServices() {
      const { historyService, currenciesService } = await import(
        '@utils/feathers-connect'
      );
      HistoryService = historyService;
      const { data } = await currenciesService.find({
        query: {
          $select: ['id', 'title'],
          type: 'coin',
        },
      });
      setCurrencies(data);
      setCurrency(data[0].id);
      loadHistory(page, data[0].id);
    }
    isBrowser && loadServices();
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <div className="card-header">
                <h5>الأسعار السابقة للعملات</h5>
                <span>
                  العملة:
                  <select
                    value={currency}
                    onChange={(e) => updateCurrency(e.target.value)}
                  >
                    {currencies.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="card-block row">
                <div className="col-sm-12 col-lg-12 col-xl-12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">{'#'}</th>
                          <th scope="col">{'سعر البيع'}</th>
                          <th scope="col">{'سعر الشراء'}</th>
                          <th scope="col">{'تاريخ التحديث'}</th>
                          <th scope="col">{'خيارات'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {history.map((item) => (
                          <tr key={item.id}>
                            <th scope="row" className="num">
                              {item.id}
                            </th>
                            <td scope="row" className="num">
                              {parseFloat(item.sell)}
                            </td>
                            <td scope="row" className="num">
                              {parseFloat(item.buy)}
                            </td>
                            <td scope="row" className="ltr">
                              {moment(item.updatedAt).format('hh:mm A, Do MMMM YYYY')}
                            </td>
                            <td scope="row" className="actions">
                              <Link
                                className="btn btn-secondary"
                                to={`/dashboard/history/edit/coin/${item.id}`}
                              >
                                تعديل
                              </Link>
                              &nbsp;
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => confirmDeleteHistory(item.id)}
                              >
                                حذف
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div>
                      <br />
                      <nav
                        className="m-b-30"
                        aria-label="Page navigation example"
                      >
                        <Pagination
                          page={page}
                          totalPages={totalPages}
                          onChange={(pageNum) => loadHistory(pageNum, currency)}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
      <Modal isOpen={isShowConfirm} toggle={() => setShowConfirm(false)}>
        <ModalHeader toggle={() => setShowConfirm(false)}>
          {'تأكيد'}
        </ModalHeader>
        <ModalBody>هل أنت متأكد من حذف هذا السجل؟</ModalBody>
        <ModalFooter>
          <Button type="button" color="warning" onClick={deleteHistory}>
            تأكيد
          </Button>
          <Button
            type="button"
            color="light"
            onClick={() => setShowConfirm(false)}
          >
            إلغاء الأمر
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default CurrenciesHistory;
