import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import { Error, Input } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser, onlyNumberKey } from '@utils/helper';
import { ErrorMessages } from '@utils/consts';

let HistoryService = () => {};

function getBackUrl(type) {
  switch (type) {
    case 'coin':
      return '/dashboard/history/currencies';
    case 'gold':
      return '/dashboard/history/gold';
    case 'material':
      return '/dashboard/history/material';
  }
}

const HistoryEdit = ({ id, type }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      await HistoryService.patch(id, data);
      setLoading(false);
      navigate(getBackUrl(type));
    }
  };

  useEffect(() => {
    async function loadHistory() {
      setLoading(true);
      const { historyService } = await import('@utils/feathers-connect');
      HistoryService = historyService;

      if (id > 0) {
        const data = await historyService.get(id);
        if (!data) {
          return;
        }
        setValue('sell', parseFloat(data.sell));
        setValue('buy', parseFloat(data.buy));
      }
      setLoading(false);
    }
    isBrowser && loadHistory();
  }, [register]);

  return (
    <Layout>
      <SEO title="Currencies - new" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>{'تحديث سعر سابق'}</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="sell">
                      سعر البيع *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="sell"
                        name="sell"
                        type="text"
                        placeholder="سعر البيع"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'sell')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="sell"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label" htmlFor="buy">
                      سعر الشراء *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control num"
                        id="buy"
                        name="buy"
                        type="text"
                        placeholder="سعر الشراء"
                        onKeyPress={onlyNumberKey}
                        hasError={hasError(errors, 'buy')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="buy"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary mr-1">
                    {'تحديث'}
                  </button>
                  <Link className="btn btn-light mr-1" to={getBackUrl(type)}>
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Preloader show={loading} />
    </Layout>
  );
};

export default HistoryEdit;
