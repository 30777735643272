import React, { useEffect, useState } from 'react';
import Layout from '@layouts/default';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Error, Select, Desc, Input } from '@components/styledComponents/index';
import Preloader from '@components/preloader/index';
import { hasError, isBrowser, prepareNotificationMessage } from '@utils/helper';
import { ErrorMessages, AppScreensList } from '@utils/consts';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { NotificationPreview, TagsContainer } from './styles';

let PushNotificationService = () => {};
let StocksService = () => {};

const NotificationNew = () => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedStock, setSelectedStock] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [stocks, setStocks] = useState([]);
  const { register, handleSubmit, errors, setValue, getValues } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async () => {
    if (!Object.keys(errors).length) {
      setLoading(true);
      const title = getValues('title');
      let tempStocks = stocks.map(function (stock) {
        return { code: stock.stockCode, sell: stock.closePrice };
      });
      let currenciesAndStocks = [...currencies, ...tempStocks];
      const content = prepareNotificationMessage(
        getValues('content'),
        currenciesAndStocks
      );
      const isNews = getValues('type') === 'news';
      const landingScreen = getValues('landingScreen');
      await PushNotificationService.create({
        to: `/topics/${
          isNews ? process.env.UpdateNotification : process.env.AdsNotification
        }`,
        title,
        body: content,
        color: isNews ? '#29b35f' : '#e84337',
        extraData: {
          landingScreen: landingScreen,
        },
      });
      setLoading(false);
      setShowSuccess(true);
    }
  };

  function selectTemplate(id) {
    if (!id) {
      return;
    }
    setSelectedTemplate(id);
    const template = templates.find((item) => item.id == id);
    setValue('content', template.content);
    setValue('type', template.type);
    setValue('landingScreen', template.landingScreen);
  }

  function addCurrency() {
    let content = getValues('content');
    content += `#${selectedCurrency}#`;
    setValue('content', content);
  }

  function addStock() {
    let content = getValues('content');
    content += `#${selectedStock}#`;
    setValue('content', content);
  }

  async function showNotificationPreview() {
    setLoading(true);
    let tempStocks = stocks.map(function (stock) {
      return { code: stock.stockCode, sell: stock.closePrice };
    });
    let currenciesAndStocks = [...currencies, ...tempStocks];
    const previewContent = prepareNotificationMessage(
      getValues('content'),
      currenciesAndStocks
    );
    setPreviewContent(previewContent);
    setShowPreview(true);
    setLoading(false);
  }

  async function loadStocks() {
    const { total, data } = await StocksService.find({
      query: {
        status: 1,
        $select: ['id', 'stockCode', 'stockName', 'closePrice'],
        $sort: { id: 1 },
      },
    });
    if (total) {
      setSelectedStock(data[0].stockCode);
      setStocks(data);
    }
  }

  useEffect(() => {
    async function loadServices() {
      setLoading(true);
      const {
        notificationTemplatesService,
        pushNotificationService,
        currenciesService,
        stocksService,
      } = await import('@utils/feathers-connect');
      StocksService = stocksService;
      PushNotificationService = pushNotificationService;

      loadStocks();
      const { data } = await notificationTemplatesService.find({
        query: {
          $select: ['id', 'title', 'content', 'type', 'landingScreen'],
        },
      });
      setTemplates(data);

      const { total, data: currenciesData } = await currenciesService.find({
        query: {
          $select: ['id', 'title', 'code', 'sell'],
          status: 1,
          $limit: 400,
        },
      });

      if (total) {
        setCurrencies(currenciesData);
        setSelectedCurrency(data[0].code);
      }
      setLoading(false);
    }

    isBrowser && loadServices();
  }, []);

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 p-10">
            <div className="card">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-header">
                  <h5>إرسال إشعار جديد</h5>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      من نموذج اشعار
                    </label>
                    <div className="col-sm-9">
                      <Select
                        onChange={(e) => selectTemplate(e.target.value)}
                        value={selectedTemplate}
                      >
                        <option value>غير محدد</option>
                        {templates.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      نوع قناة الاشعار
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="type"
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      >
                        <option value="news">إخباري</option>
                        <option value="ads">إعلاني</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="type"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      الشاشة الوجهة
                    </label>
                    <div className="col-sm-9">
                      <select
                        name="landingScreen"
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      >
                        {AppScreensList.map((item) => (
                          <option key={item.id} value={item.value}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="landingScreen"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="title-notify"
                    >
                      العنوان *
                    </label>
                    <div className="col-sm-9">
                      <Input
                        className="form-control"
                        name="title"
                        id="currency-title"
                        type="text"
                        placeholder="العنوان"
                        hasError={hasError(errors, 'title')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="title"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-3 col-form-label"
                      htmlFor="currency-name"
                    >
                      النص *
                    </label>
                    <div className="col-sm-9">
                      <TagsContainer>
                        <span>إضافة عملة</span>
                        &nbsp;&nbsp;
                        <Select
                          id="currency-dropdown"
                          name="currency"
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                          defaultValue={selectedCurrency}
                        >
                          {currencies.map((item) => (
                            <option key={item.code} value={item.code}>
                              {item.title}
                            </option>
                          ))}
                        </Select>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={addCurrency}
                        >
                          أضف
                        </button>
                      </TagsContainer>
                      <TagsContainer>
                        <span>إضافة سهم</span>
                        &nbsp;&nbsp;
                        <Select
                          id="stocks-dropdown"
                          name="stock"
                          onChange={(e) => setSelectedStock(e.target.value)}
                          defaultValue={selectedStock}
                        >
                          {stocks.map((item) => (
                            <option key={item.stockCode} value={item.stockCode}>
                              {item.stockName}
                            </option>
                          ))}
                        </Select>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-info"
                          onClick={addStock}
                        >
                          أضف
                        </button>
                      </TagsContainer>
                      <Desc
                        className="form-control"
                        id="notification-content"
                        name="content"
                        placeholder="نص الاشعار"
                        hasError={hasError(errors, 'content')}
                        ref={register({
                          required: ErrorMessages.required,
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="content"
                        render={({ message }) => <Error>{message}</Error>}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="button"
                    className="btn btn-secondary mr-1"
                    onClick={showNotificationPreview}
                  >
                    معاينة
                  </button>
                  <button type="submit" className="btn btn-primary mr-1">
                    إرسال
                  </button>
                  <Link
                    className="btn btn-light mr-1"
                    to={'/dashboard/notification-templates'}
                  >
                    إلغاء الأمر
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showPreview} toggle={() => setShowPreview(false)}>
        <ModalHeader toggle={() => setShowPreview(false)}>
          {'معاينة'}
        </ModalHeader>
        <ModalBody>
          <NotificationPreview>{getValues('title')}</NotificationPreview>
          <NotificationPreview>{previewContent}</NotificationPreview>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowPreview(false)}>
            إغلاق
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showSuccess} toggle={() => setShowSuccess(false)}>
        <ModalHeader toggle={() => setShowSuccess(false)}>
          {'معلومة'}
        </ModalHeader>
        <ModalBody>تم الإرسال بنجاح!</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowSuccess(false)}>
            إغلاق
          </Button>
        </ModalFooter>
      </Modal>
      <Preloader show={loading} />
    </Layout>
  );
};

export default NotificationNew;
